<script>
    import DrawerItem from "./DrawerItem.svelte";
    import { stores, goto } from "@sapper/app";
    import { showMobileDrawer, inAppView } from "../../store";
    import { fade, fly } from "svelte/transition";
    import {onMount} from "svelte";

    const { session, page } = stores();

    let name = "";
    let type = 0;
    let showMobileAppBtns = true;
    let mounted = false;

    onMount( () => {
        mounted = true;
    });

    $: if( $session && $session.user ) {
        name = "";
        if( $session.user['Фамилия'] ) name += $session.user['Фамилия'] + " ";
        if( $session.user['Имя'] ) name += $session.user['Имя'] + " ";
        if( $session.user['Отчество'] ) name += $session.user['Отчество'];
    }
    $: if( $session && $session.user && $session.user.type) type = $session.user.type;
    $: if($page) $showMobileDrawer = false;
</script>

<style>
    .logo {
        height: 60px;
    }

    .drawer-container {
        height: 100%;
        overflow-y: auto;
        /*position: fixed;
        z-index: 55;
        top: 0;
        left: 0;*/
    }

    .drawer-back {
        display: none;
    }

    .drawer {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 15px;
        padding: 5px;
        background: #fff;
    }

    @media all and (max-width: 1024px) {
        .drawer-container {
            position: fixed;
            height: 100%;
            width: 280px;
            top: 0;
            left: -100vw;
            transition: 500ms ease-in-out;
            z-index: 55;
        }

        .drawer-wrap {
            position: relative;
            height: 100%;
            width: 100%;
        }

        .drawer-container__show {
            left: 0!important;
        }

        .drawer-back {
            display: block!important;
            width: 100vw;
            height: 100%;
            position: fixed;
            z-index: 4;
            top: 0;
            left: 0;
            background: rgba(84,91,98, 0.5);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
        }

        .drawer {
            position: relative;
            z-index: 2;
            width: 100%;
            box-shadow: 0 0 5px 0 #615d5d;
            border-radius: 0!important;
        }
    }
</style>

<div class="drawer-container p-0" class:drawer-container__show={$showMobileDrawer}>
    <img src="logo.png" class="logo" alt="">
    <div class="p-0 drawer-wrap mt-3">
        <div class="h-100 drawer pb-0 px-0">
            <div>
                <!--
                {#if $session && $session.user}
                    <div class="cursor-pointer d-flex align-items-center justify-content-between" style="padding: 17px;">
                        <div class="d-flex align-items-center" on:click={ () => goto("/profile")}>
                            <div class="avatar" style="margin-right: 10px;">
                                { $session.user['Имя'] }
                            </div>
                            <div class="text-gold" style="font-size: 18px">
                                { $session.user['Имя'] } { $session.user['Фамилия'][0] }.
                            </div>
                        </div>
                        <div on:click={ () => goto("/notifications")}>
                            <i class="fas fa-bell text-secondary fa-15x"></i>
                        </div>
                    </div>
                {/if}
                -->

                {#if type === 101}
                    <DrawerItem icon={"fas fa-cogs fa-15x"} link={"admin/dashboard"} label="Админ" />
                {/if}

                {#if $session && $session.user}
                    <DrawerItem icon={"fas fa-home fa-15x"} link={"main"} label="Главная" />
                {:else}
                    <DrawerItem icon={"fas fa-sign-in-alt fa-15x"} link={"auth"} label="Войти" />
                {/if}

                <!-- DrawerItem icon={"fas fa-user-md fa-15x"} link={"specialists"} label="Врачи" /-->

                <DrawerItem icon={"fas fa-calendar-plus fa-15x"} link={"online"} label="Онлайн-запись" />

                {#if $session && $session.user}
                    <DrawerItem icon={"fas fa-clipboard-list fa-15x"} link={"/cart"} label="Медкарта" />
                    <!-- DrawerItem icon={"fas fa-gift fa-15x"} link={"/treatment"} label="Программы лечения" / -->
                    <!-- DrawerItem icon={"fas fa-gift fa-15x"} link={"/bonuses"} label="Бонусная программа" /-->
                    <DrawerItem icon={"far fa-bell fa-15x"} link={"notifications"} label="Уведомления" />

                    {#if type !== 101}
                        <DrawerItem icon={"fas fa-user-circle fa-15x"} link={"profile"} label={"Профиль"} />
                    {/if}
                {/if}

                <DrawerItem icon={"fa-solid fa-house-medical fa-15x"} link={"contacts"} label={"Контакты"} />

                <!-- DrawerItem icon={"far fa-question-circle fa-15x"} link={"support"} label="Сообщить о проблеме" / -->

                <DrawerItem icon={"far fa-info-circle fa-15x"} link={"documents"} label="Документы" />
            </div>
        </div>
    </div>
</div>

{#if $showMobileDrawer}
    <div class="drawer-back"
         on:click={ () => $showMobileDrawer = false }
         transition:fade={{ delay: 0, duration: 300, opacity: 0 }}
    ></div>
{/if}