<script>
    import { stores } from "@sapper/app";
    import { fade, fly } from "svelte/transition";
    const { session } = stores();
    import { onMount } from "svelte";

    let timer, show, mounted;

    onMount( () => {
        mounted = true;
        //checkShow();
        //timer = setTimeout(hideBanner, 4000);
    });

    //$: checkShow($session);

    function checkShow(){
        if(!mounted) return;
        if( $session && $session.user && window.sessionStorage && window?.sessionStorage?.welcomeBannerShown !== "true") show = true;
    }

    function hideBanner(){
        show = false;
        window.sessionStorage.setItem("welcomeBannerShown", "true");
        clearTimeout(timer);
    }
</script>

<style>
    .welcome-banner {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        height: 100%;
        width: 100%;
        /*background-image: url('/intro.jpg');*/
        background-size: cover;
        background-position: center;
    }

    .bg-w {
        background: rgba(255,255,255,0.9);
        backdrop-filter: blur(5px);
    }

    .logo {
        width: 70vw;
        max-width: 300px;
    }

    .txt {
        font-size: 3rem;
    }

    @media all and (max-width: 1024px) {
        .txt {
            font-size: 2rem;
        }
    }
</style>


{#if show}
    <div class="welcome-banner" transition:fade={{ delay: 0, duration: 500 }}>
        <div class="h-100 w-100 d-flex align-items-center flex-column justify-content-start bg-w pt-5">

            <img src="logo.png" class="logo mt-5" alt="" transition:fly={{ delay: 600, duration: 700, y: -400, opacity: 0 }}>

            <div class="p-4 d-none d-lg-block"></div>

            <div class="txt text-center text-white1 mt-5" transition:fly={{ delay: 600, duration: 700, y: 400, opacity: 0 }}>
                Добрый день,
            </div>
            <div class="txt text-center text-white1" transition:fly={{ delay: 900, duration: 700, y: 400, opacity: 0 }}>
                {$session.user['Имя']} {$session.user['Отчество']}!
            </div>
        </div>
    </div>
{/if}
