<script>
    export let link, icon = "", strict = false, label = "", img = "", imgDark = "", target = "_self";
    import { stores } from "@sapper/app";

    const { page } = stores();
    let active = false;

    $: {
        if(strict) active = ($page && ($page.path == link));
        else active = ($page && $page.path && $page.path.indexOf(link) > -1);
    }
</script>

<style>
    a {
        color: #909090;
        display: flex;
        align-items: center;
        text-decoration: none;
        transition: 300ms linear;
        border-radius: 10px;
        font-size: 18px;
        padding: 15px 10px;
        /*margin: 2px 5px;*/
    }

    .dark-img {
        display: none;
    }

    a:hover {
        /*background-color: var(--light-green);*/
        color: var(--gold);
    }

    a:hover .white-img {
        display: none;
    }

    a:hover .dark-img {
        display: inline;
    }

    .active {
        color: var(--gold)!important;
        /*background-color: var(--light-green)!important;*/
    }

    .icon {
        width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
    }
</style>

<div class="overflow-hidden">
    <a href={link} class:active={ active } { target }  class="">
        <div class="icon" style="margin-right: 5px;" class:d-none={!icon}>
            <i class={icon}></i>
        </div>
        <div class="icon" class:d-none={!img}>
            <img class="white-img" src={img} height="20" alt="">
            <img class="dark-img" src={imgDark} height="20" alt="">
        </div>
        <div class="">
            {label}
        </div>
    </a>
</div>