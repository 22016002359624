<script>
    import BottomMenuItem from "./BottomMenuItem.svelte";
    import { modalShowed, h } from "../../store";
    import { stores } from "@sapper/app";

    const { session } = stores();

    $: loggedIn = ($session && $session.user);

    let hideMenu = false;
    let initHeight = 0;
    const onlineWidgetLink = "https://widget.med.me/?NETWORK_ID=431&font.name=Proxima&font.regular=ProximaRegular&font.medium=ProximaMedium&font.bold=ProximaBold&referrer=https%3A%2F%2Fredclinic.ru%2F&hostname=https%3A%2F%2Fcabinet.redclinic.ru%2Fauth%2F";

    $: if(initHeight < $h) initHeight = $h;
    $: hideMenu = ($h < initHeight * 0.7);
</script>

<style>
    .navbar {
        box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.05);
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 0;
        width: 100vw;
        bottom: 0;
        z-index: 49;
        height: 55px;
    }

    .grid {
        width: 100%;
        height: 55px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .extend {
        display: none;
        width: 100%;
        height: 15px;
    }

    .logged-in {
        grid-template-columns: repeat(4, 1fr)!important;
    }

    @media all and (min-height: 668px){
        .extend {
            display: block!important;
        }
    }
</style>

<nav class="m-0 p-0 navbar d-lg-none" class:z1={ $modalShowed } class:d-none={ hideMenu } >
    <div
            class="grid bg-white"
         class:logged-in={loggedIn}
    >
        {#if loggedIn}
            <BottomMenuItem
                    icon={"fas fa-home"}
                    link={"main"}
                    label="Главная"
            />
            <BottomMenuItem
                    icon={"fas fa-clipboard-list"}
                    link={"cart"}
                    label="Медкарта"
                    includedURls={[ "cart", "appointments", "analyzes" ]}
            />
        {:else}
            <BottomMenuItem
                    icon={"fas fa-user-circle"}
                    link={"auth"}
                    label="Войти"
            />
        {/if}

        <BottomMenuItem
                icon={"fas fa-calendar-plus"}
                link={"online"}
                label="Онлайн-запись"
        />

        {#if loggedIn}
            <BottomMenuItem
                    icon={"fas fa-bars"}
                    link={"more"}
                    label="Ещё"
                    includedURls={[ "contacts", "chat", "notifications", "support", "profile", "documents" ]}
            />
        {:else}
            <BottomMenuItem
                    icon={"fas fa-location-dot"}
                    link={"contacts"}
                    label="Контакты"
                    includedURls={[ "contacts" ]}
            />
        {/if}

    </div>
    <div class="bg-white extend"></div>
</nav>