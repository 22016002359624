<script context="module">
	export async function preload(page, session) {
		try {
			await this.fetch("/profile/check.json");
		}
		catch (e) {
			console.log(e);
		}
	}
</script>

<script>
	export let segment;

	import Loader from "../components/Loader.svelte";
	import TopNav from "../components/nav/TopNav.svelte";
	import BottomMenu from "../components/nav/BottomMenu.svelte";
	import Drawer from "../components/nav/Drawer.svelte";
	import WelcomeBanner from "../components/home/WelcomeBanner.svelte";
	import PWABanner from "../components/PWABanner.svelte";
	import NoConnectionWarning from "../components/NoConnectionWarning.svelte";
	import RefreshControl from "../components/nav/RefreshControl.svelte";
	import { appVersion, loading, showMobileDrawer, w, h, inAppView, noModalLeftPadding, bodyNoScroll, pwaMode, modalShowed, forceCloseModal } from "../store";
	import { stores, goto } from '@sapper/app';
	import { NotificationDisplay } from '@beyonk/svelte-notifications';
	import { onMount } from "svelte";
	import axios from "axios";
	import { updateSettings } from "./_helpers";
	import { settings } from "../store";

	const { preloading, session, page } = stores();

	$: if(mounted && $page && ($page.path === "/main" || $page.path === "/contacts" || $page.path === "/notifications")) {
		updateSettings($page);
	}

	let timeout = 5000; // notification self-destruct timeout
	let themes = { // These are the defaults
		danger: '#bb2124',
		success: '#159d97',
		warning: '#f0ad4e',
		info: '#5bc0de',
		default: '#aaaaaa' // relates to simply '.show()'
	};
	let bodyTag, showInstallPromotion, deferredPrompt, noConnectionWarning = false, mounted = false;
	let maintenanceMode = 0;

	$: $noModalLeftPadding = (segment === "widget");

	$: {
		if(bodyTag && $bodyNoScroll) bodyTag.classList.add('no-scroll');
		if(bodyTag && !$bodyNoScroll) bodyTag.classList.remove('no-scroll');
	}

	onMount( () => {

		bodyTag = document.body;
		mounted = true;

		checkMaintenanceMode();

		if(window.matchMedia('(display-mode: standalone)').matches){
			$pwaMode = true;
		}

		if(window && window.navigator && !window.navigator.onLine){
			noConnectionWarning = true;
		}

		window.addEventListener('offline', function(event){
			noConnectionWarning = true;
		});
		window.addEventListener('online', function(event){
			noConnectionWarning = false;
		});

		if(window.ReactNativeWebView) {
			$inAppView = true;
			window.addPushListener = addPushListener1;
			window.setAppVersion = setAppVersion;
			window.removePushListener = removePushListener1;
			window.backButtonPressed = androidBackButtonPressed;

			let msg = { action: "checkAppVersion" };
			window.ReactNativeWebView.postMessage(JSON.stringify(msg));

			msg = { action: "login" };
			window.ReactNativeWebView.postMessage(JSON.stringify(msg));
		}

		if($pwaMode || window.ReactNativeWebView){
			window.onRoistatModuleLoaded = function () {
				window.roistat.disableCallTracking();
			};
		}
	});

	function setAppVersion(v){
		if(v) $appVersion = v;
	}

	async function checkMaintenanceMode(){
		try {
			const { data } = await axios.post("/support/maintenance", {});
			maintenanceMode = +data.maintenanceMode || 0;
		}
		catch (e) {
			console.log(e);
		}
	}

	function androidBackButtonPressed(){
		if($modalShowed && !$forceCloseModal) $forceCloseModal = true;
		if(!$modalShowed && !$forceCloseModal) goto("/main");
	}

	async function addPushListener1(token, os){
		if(token) {
			try {
				const res = await axios.post('/notifications/notifications.json', { pushId: token, os }, { withCredentials: true });
			}
			catch (err) {
				console.log(err);
			}
		}

		$loading = false;

		//window.location.href = "/main";
	}

	async function removePushListener1(token, guid){
		if(token && guid) {
			try {
				const res = await axios.patch('/notifications/notifications.json', { pushId: token, guid }, {withCredentials: true});
			} catch (err) {
				console.log(err);
			}
		}

		$loading = false;
		window.location.href = "/auth/logout.json";
	}

	function callPhone(){
		if(mounted && window.ReactNativeWebView) {
			let msg = {
				action: "call",
				phone: "+79788332216"
			};
			window.ReactNativeWebView.postMessage(JSON.stringify(msg));
		}
	}
</script>

<svelte:window bind:innerWidth={$w} bind:innerHeight={$h} />

<style>
	.fio {
		font-size: 16px;
		line-height: 19px;
		font-weight: 700;
		font-family: NotoBold, sans-serif;
		white-space: nowrap;
		text-decoration: none;
		color: var(--dark);
	}

	main {
		position: relative;
		padding-top: 1rem;
		word-break: break-word;
		display: grid;
		grid-template-columns: 1fr 800px 1fr;
		margin: auto;
		grid-gap: 25px 40px;
	}

	.grid {
		display: grid!important;
		grid-template-columns: 260px 1fr;
		grid-column-gap: 1rem;
		height: 100%;
		width: 100vw;
		overflow-x: hidden;
		overflow-y: auto;
	}

	@media all and (max-width: 1024px) {
		main {
			padding: 0 0 40px!important;
			grid-template-columns: 1fr;
			grid-gap: 0;
		}

		.logo {
			display: none;
		}

		.grid {
			grid-template-columns: 1fr!important;
			grid-template-rows: max-content 1fr;
			grid-column-gap: 0;
		}

		.content {
			padding: 65px 0 4rem 0!important;
			overflow-y: auto;
		}

		.content1 {
			padding-top: 0!important;
		}
	}

	.grid .content {
		height: 100%;
		padding: 1rem;
		overflow-x: hidden;
	}

	.err {
		position: fixed;
		height: 100vh;
		width: 100vw;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #FFF;
		z-index: 100;
		flex-direction: column;
	}

	.content1 {
		max-width: 800px;
		padding-top: 100px;
	}

	.bell {
		position: relative;
	}

	.top-nav img {
		height: 30px;
	}

	.bell-badge {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
		top: 0;
		right: -4px;
		background: var(--red);
		width: 20px;
		height: 20px;
		padding: 1px 3px 1px 3px;
		border-radius: 26px;
		font-size: 10px;
		line-height: 18px;
		color: #fff;
	}

	.top-nav {
		height: 60px;
		display: none;
		align-items: center;
		grid-template-columns: 1fr max-content max-content;
		grid-gap: 2rem;
		margin-bottom: 1rem;
	}

	@media screen and (min-width: 768px) {
		.top-nav {
			display: grid!important;
		}
	}
</style>

{#if maintenanceMode}
	<div class="err">
		<img src="logo.png" height="75" alt="">
		<div class="h1 mt-5 mb-5">
			Уважаемые пациенты!
		</div>
		<div class="text-center h3">
			<div class="mb-3">
				Мы проводим технические работы, чтобы стать ещё лучше.
			</div>
			<div>
				В скором времени личный кабинет будет доступен.
			</div>
		</div>
	</div>
{/if}

{#if segment === "test"}
	<slot></slot>
{/if}

{#if segment === "auth1"}
	<slot></slot>
{/if}

{#if segment === "widget1"}
	<slot></slot>
{/if}

{#if segment === "admin"}
	<slot></slot>
	<Drawer />
{/if}

{#if !( segment === "test" || segment === "admin" || segment === "auth1" || segment === "widget1" )}

	<div style="padding: 15px">
		<TopNav />

		<main class:grid1={segment && segment !== "auth"} id="main">
			<div class="d-flex align-items-start justify-content-end">
				<Drawer />
			</div>

			<div class="position-relative">
				<div class="top-nav">
					<a class="fio" href="/profile">
						{#if $session.user}
							{ $session.user['Фамилия'] } { $session.user['Имя'] } { $session.user['Отчество'] }
						{/if}
					</a>
					<div>
						<a
							class="bell"
							class:d-none={ !($session && $session.user) }
							href="/notifications"
						>
							<img src="/icons/bell.svg" alt="">
							<div class="bell-badge">
								{ isNaN($settings.counter) ? 0 : $settings.counter }
							</div>
						</a>
					</div>
					<div>
						<a
							href="/auth/logout.json"
							class:d-none={ !($session && $session.user) }
						>
							<img src="/icons/sign-out.svg" alt="">
						</a>
					</div>
				</div>
				<slot></slot>
			</div>

			<div></div>

		</main>
	</div>
{/if}

<BottomMenu />

<Loader padding={segment && segment !== "auth"} />

<NotificationDisplay {themes} {timeout} />

<WelcomeBanner />

{#if noConnectionWarning}
	<NoConnectionWarning />
{/if}