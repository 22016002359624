<script>
    import { showMobileDrawer, w, settings, loading } from "../../store";
    import { stores, goto } from "@sapper/app";
    import axios from "axios";
    import {successNotification, selectProfile, errorNotification} from "../../routes/_helpers";

    const { session } = stores();

    let showProfilesList;
    let profilesLoading;
    let profiles = [];

    function toggleDrawer(){
        $showMobileDrawer = !$showMobileDrawer;
    }

    function showProfiles(){
        getProfiles();
    }

    async function getProfiles(){
        showProfilesList = true;
        profilesLoading = true;

        try {
            const { data } = await axios.get("/profile/profiles.json", { withCredentials: true });
            profiles = [...data];
            $session.user['Клиенты'] = [...data];
            if(profiles.length === 1) {
                showProfilesList = false;
                goto("/profile");
            }
        }
        catch (err){
            errorNotification("Не удалось получить профили");
        }

        profilesLoading = false;
    }

    async function selectProfile_old(profile){

        $loading = true;

        try {
            await axios.post(
                `/profile/change.json`,
                { profile: profile['Ссылка'] },
                { withCredentials: true }
            );
            //successNotification("Профиль переключен");
            window.location.reload();
        }
        catch(err){
            errorNotification("Ошибка при переключении профиля");
            console.log(err);
        }

        $loading = false;
    }

    function logout(){
        if(window.ReactNativeWebView){
            const msg = {
                action: "logout",
                guid: $session.user.guid,
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(msg));
        }
        else window.location.href = "/auth/logout.json";
    }
</script>

{#if showProfilesList}
    <div class="profiles-list shadow-sm">
        <div class="text-right mr-n2 mt-n3 mb-3">
            <i class="fas fa-times fa-2x" on:click={ () => showProfilesList = false }></i>
        </div>
        {#if profilesLoading}
            <div class="text-center text-18">
                Получаем список профилей...
            </div>
        {:else}
            {#each profiles as profile (profile['Ссылка'])}
                <div
                    class="fio d-flex align-items-center mb-3"
                    class:text-danger={profile['Ссылка'] === $session.user['Клиент']}
                    on:click={ () => selectProfile(profile) }
                >
                    <img height="20" src={ profile['Пол'] === "Ж" ? "/female.png" : "/male.png" } alt="">
                    <div class="ml-2">
                        { profile['Фамилия'] } { profile['Имя'] } { profile['Отчество'] }
                    </div>
                </div>
            {/each}
        {/if}
    </div>
{/if}

{#if $session && $session.user}
    <div class="d-md-none">
        <div class="grid">
            <div class="text-dark text-decoration-none" on:click={ showProfiles }>
                <div class="fio">
                    <b>
                        { $session.user['Фамилия'] }
                    </b>
                </div>
                <div class="fio">
                    <b>
                        { $session.user['Имя'] } { $session.user['Отчество'] }
                    </b>
                    {#if $session.user['Клиенты'] && $session.user['Клиенты'].length > 1 }
                        <i class="ml-2 fas fa-chevron-down fa-1x"></i>
                    {/if}
                </div>
            </div>
            <a class="bell" href="/notifications">
                <img src="/icons/bell.svg" alt="">
                <div class="bell-badge" class:d-none={!$settings.counter}>
                    { $settings.counter }
                </div>
            </a>
            <div class="text-right">
                <button class="btn" style="padding: 0!important;" on:click={logout}>
                    <img src="/icons/sign-out.svg" alt="">
                </button>
            </div>
        </div>
    </div>
{/if}

<style>
    .profiles-list {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        background: rgba(255,255,255);
        padding: 1.5rem 1.5rem 0;
        z-index: 3;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr max-content max-content;
        grid-gap: 25px;
        align-items: center;
        margin-bottom: 35px;
    }

    .bell {
        position: relative;
    }

    .bell-badge {
        position: absolute;
        z-index: 2;
        top: 0;
        right: -4px;
        background: var(--red);
        width: 18px;
        height: 14px;
        padding: 1px 3px 1px 3px;
        border-radius: 26px;
        font-size: 10px;
        line-height: 12px;
        color: #fff;
        text-align: center;
    }

    .fio {
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
        font-family: 'NotoBold', sans-serif;
        white-space: nowrap;
    }
</style>

