<script>
    function reload(){
        window.location.reload();
    }
</script>

<style>
    .no-conn {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        height: 100vh;
        width: 100vw;
        background: var(--light);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 2rem;
    }
</style>

<div class="no-conn">
    <div class="text-center mb-4">
        <i class="fas fa-wifi fa-5x text-danger"></i>
    </div>
    <div class="text-center h3">
        Нет подключения к Интернет
    </div>
    <div class="text-center my-4">
        Пожалуйста, проверьте подключение Вашего устройства к сети Интернет
    </div>
    <div class="text-center">
        <button class="btn btn-gold rounded-pill" type="button" on:click={ reload }>
            <i class="fas fa-redo mr-2"></i>
            Обновить
        </button>
    </div>
</div>