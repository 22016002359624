<script>
    export let
        link = "",
        icon = "",
        strict = false,
        label = "",
        includedURls = [],
        mobileAppLink = ""
    ;
    import { stores } from "@sapper/app";
    import { onMount } from "svelte";

    const { page } = stores();
    let active = false;
    let mounted;

    onMount(() => {
        mounted = true;
    });

    $: {
        active = false;

        if(strict) active = ($page && ($page.path == link));
        else {

            if($page && $page.path && $page.path.indexOf(link) > -1) {
                active = true;
            }

            if(!active && includedURls && includedURls.length){
                includedURls.forEach( item => {
                    if($page && $page.path && $page.path.indexOf(item) > -1) {
                        active = true;
                    }
                });
            }
        }
    }

    const openInApp = () => {
        if(!window.ReactNativeWebView) return;
        let msg = {
            action: "openLink",
            link: mobileAppLink
        };

        window.ReactNativeWebView.postMessage(JSON.stringify(msg));
    };
</script>

<style>
    .link {
        cursor: pointer;
        color: var(--gray);
        display: flex!important;
        align-items: center!important;
        flex-direction: column;
        text-decoration: none;
    }

    .link div {
        font-size: 0.75rem!important;
    }

    .active {
        color: var(--gold)!important;
    }
</style>

{#if mounted}
    {#if mobileAppLink && window.ReactNativeWebView}
        <div
                class:active={ active }
                on:click|preventDefault|stopPropagation = { openInApp }
                class="link d-flex align-items-center justify-content-center py-0 px-0"
        >
            <i class={icon + " fa-15x"}></i>
            <div class="text-center mt-1">
                {label}
            </div>
        </div>
    {:else}
        <a
                href={link}
                class:active={ active }
                class="link d-flex align-items-center justify-content-center py-0 px-0"
        >
            <i class={icon + " fa-15x"}></i>
            <div class="text-center mt-1">
                {label}
            </div>
        </a>
    {/if}
{/if}