<script>
    import { fade, fly } from 'svelte/transition';
    import { quintInOut } from 'svelte/easing';
    import { loading, loadingMessage, noModalLeftPadding } from "../store";
    import { stores } from '@sapper/app';
    const { preloading } = stores();

    export let padding = false;

    let timer, tooLong;

    $: startTimer($loading);

    function startTimer(){
        if(timer) clearTimeout(timer);
    }

    function hideLoader(){
        clearTimeout(timer);
        $loading = false;
        tooLong = false;
    }
</script>

<style>
    .loader {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 100;
        background: rgba(255,255,255,0.5);
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .spinner-border {
        color: var(--gold)!important;
        width: 4rem;
        height: 4rem;
    }

    .padding {
        padding-left: 250px;
    }

    @media all and (max-width: 1024px) {
        .padding {
            padding-left: 0!important;
        }
    }

    .dna {
        transform: rotate(90deg);
    }

    .circ {
        display: flex;
        width: 180px;
        height: 180px;
        align-items: center;
        justify-content: center;
        border-radius: 160px;
        /*border: 5px solid var(--primary);*/
        background: #fff;
    }

    .logo-loader {
        width: 100px;
        height: 100px;
        animation: anim 1s infinite ease-in-out;
    }

    @keyframes anim {
        0% {
            rotate: 0;
        }

        40% {
            rotate: 360deg;
        }

        100% {
            rotate: 360deg;
        }
    }
</style>

{#if $loading || $preloading}
    <div
        class="loader"
        class:padding1={padding}
        class:pl-0={$noModalLeftPadding}
        transition:fade={{ delay: 100, duration: 500, opacity: 0, easing: quintInOut }}
    >
        <div class="circ shadow-sm">
            <!-- div class="spinner spinner-border"></div -->
            <img src="/logo-512.png" class="logo-loader" alt="">
        </div>
        <h3 class="text-center my-4" class:d-none={ !$loadingMessage }>
            { $loadingMessage }
        </h3>
    </div>
{/if}